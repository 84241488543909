@import '../../../_mizuno/client/components/variables.css';

.sportFilter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sportFilter .sportItem {
  max-width: 400px;
  margin: 20px;
  background: white;
  cursor: pointer;
}

.sportFilter .sportItem .sportName {
  font-size: 2em;
  text-transform: uppercase;
  text-align: center;
  margin: 20px 0 40px 0;
  color: var(--color-darkGray);
}

.sportFilter .sportItem img {
  width: 100%;
  height: auto;
}
