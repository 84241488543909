.recipeFinder {
  padding: 10px 10px 20px 10px;
}

.recipeFinder .inputToggle {
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}

.recipeFinder .input {
  margin: 10px 5px;
  display: flex;
}

.recipeFinder .input .textInput {
  width: 60px;
}

.recipeFinder .input .button {
  width: 36px;
  height: 36px;
  margin-left: 10px;
}
