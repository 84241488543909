.sportChanger {
  padding: 10px 10px;
}

.sportChanger button {
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}
