.Approval {
  padding: 20px 16px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.Approval-item {
  display: flex;
  align-items: center;
}

.Approval-item > input {
  margin-right: 8px;
  align-self: flex-start;
  height: 13px;
  width: 13px;
  min-width: 13px;
}

.Approval-asterisk {
  padding-left: 4px;
  color: red;
}
