@import '../../../_mizuno/client/components/variables.css';

#OrderStatus {
  font-size: 14px;
}

#OrderStatus .OrderHeader {
  background-color: var(--color-warmWhite);
  color: var(--color-gray);
  height: 4rem;
  padding: 0 1rem;
}

#OrderStatus .OrderBody {
  padding: 1rem;
}

#OrderStatus .Title {
  text-transform: uppercase;
  line-height: 4rem;
}

#OrderStatus .OrderError {
  font-size: 1.4rem;
  color: var(--color--finalizeBlock-table);
}

#OrderStatus table,
#OrderStatus th,
#OrderStatus td {
  border: none;
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;
  color: var(--color-midDarkGray);
  text-align: left;
}

#OrderStatus th,
#OrderStatus td {
  padding: 0 1rem 1rem 1rem;
}

#OrderStatus th {
  width: 33%;
}
