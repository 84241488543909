@import '../../_mizuno/client/components/variables.css';

.styledProductNameSeparator {
  font-size: 18px;
  font-weight: bold;
}

.fullCustomDownloadTemplate {
  font-size: 1.2em;
  color: var(--color-darkGray);
  margin-bottom: 12px;
}

.rosterUpload {
  font-size: 1.2em;
  color: var(--color-darkGray);
  margin: 12px 0;
}

@media (max-width: 999px) {
  /* Fine-tune preview image position on mobile screens. */
  .preview-images-outer {
    margin-top: 50px;
  }
}

#sidebar .error {
  color: var(--color-red);
  font-weight: bold;
  font-size: 12px;
}
