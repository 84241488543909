.previewImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.previewImageShow img {
  height: 100%;
  max-height: 70vh;
  width: auto;
}
.previewImageList {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.previewImageThumb img {
  max-width: 200px;
  height: auto;
  width: 100%;
  cursor: pointer;
}
@media (max-width: 768px) {
  .previewImageSmall {
    max-width: 100px;
  }
  .previewImageShow img {
    height: 100%;
    max-height: 50vh;
    width: auto;
  }
  .previewImageThumb img {
    max-width: 100px;
    height: auto;
    width: 100%;
    cursor: pointer;
  }
}
