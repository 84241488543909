@import '../../../_mizuno/client/components/variables.css';

.roster.mod-flex {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.roster.mod-flex tbody {
  flex-direction: column;
}

.roster.mod-flex .roster-row {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.roster.mod-flex .roster-row table {
  width: 100%;
  border: 0 none;
  border-collapse: collapse;
}

.roster.mod-flex .roster-cell {
  width: 100%;
}
.roster.mod-flex td:not(:last-child) {
  padding-right: 0;
}

.roster.mod-flex tr:first-child td {
  padding-bottom: 0rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: capitalize;
}

.roster-row-section {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 0.4rem;
}

.roster-cell-input-label {
  color: var(--color-midDarkGray);
  font-size: 1.1rem;
}

.roster.mod-flex .roster-cell-preview,
.roster.mod-flex .roster-cell-remove {
  width: var(--width-normal--rosterActionButton);
}

.roster.mod-flex .roster-cell-size {
  min-width: 8rem;
}
