@import '../../_mizuno/client/components/variables.css';

#renderer {
  width: 100%;
  height: 100%;
}

#renderer canvas {
  width: 100%;
  height: 100%;
  outline: 0;
}

#renderer .loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview,
.renderer {
  /* Same color as renderer background. */
  background: #e5e5e5 !important;
}
