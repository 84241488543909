@import '../../../_mizuno/client/components/variables.css';

.SidebarPlacements .textInput {
  padding: 0;
  margin-bottom: 10px;
}

.SidebarPlacements .placementGroup:not(:first-child) {
  margin-top: 10px;
  border-top: 1px solid var(--color-lighterGray);
}

.SidebarPlacements .placementGroup .name {
  font-size: 1.4em;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-midDarkGray);
  margin-top: 10px;
  margin-bottom: 5px;
  padding-left: 10px;
}

.SidebarPlacements .placementGroup .mode {
  margin: 5px 10px;
}

.SidebarPlacements .placementGroup .mode .tile {
  height: 4rem;
}

.SidebarPlacements .placementGroup .mode .tileLabel {
  font-size: 1.5em;
}
