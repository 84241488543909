@import '../../../_mizuno/client/components/variables.css';

.productSelection {
  font-size: 14px;
  padding: 10px 20px;
  border-bottom: 0.1rem solid var(--borderColor-bottom--contentBody);
}

.productSelection button {
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}
